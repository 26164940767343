<script>
import EmailIcon from '../assets/email.svg?component';
import DownloadIcon from '../assets/download.svg?component';
import CloseIcon from '../assets/close.svg?component';
import Point from './Point.vue';
import ArrowDownIcon from "../assets/arrow-down.svg?component";
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true,
      default: 1
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  components: {ArrowDownIcon, Point, EmailIcon, DownloadIcon, CloseIcon},
  methods: {
    emailClick(e){
      e.preventDefault();
      e.stopPropagation();
      this.$emit('create-pdf', 'email');
    },
    downloadClick(e){
      e.preventDefault();
      e.stopPropagation();
      this.$emit('create-pdf', 'download');
    }
  }
}
</script>

<template>
  <div class="resultCardHeader">
    <Point :number="index"/>
    <div class="resultCardHeader__details">
      <h3>{{ item.nazwa }}</h3>
      <p>{{ item.miasto }}, {{ item.ulica }}</p>
    </div>
    <div class="resultCardHeader__controls">
      <a class="email" v-show="isOpen" @click="emailClick">
        <EmailIcon />
      </a>
      <a class="download" v-show="isOpen" @click="downloadClick">
        <DownloadIcon />
      </a>
      <span class="close" v-show="isOpen">
        <CloseIcon />
      </span>
      <span class="open" v-show="!isOpen">
        <ArrowDownIcon/>
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.resultCardHeader {
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 992px){
    flex-direction: column;
    align-items: baseline;
  }

  &__details{
    margin-left: 28px;

    @media (max-width: 992px){
      margin-left: 0;
    }

    h3,p{
      margin: 0;
    }
  }

  &__controls{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-left: auto;

    @media (max-width: 992px){
      display: none;
    }
  }
}
</style>