class SearchForm{
    constructor(searchData) {
        this.data = searchData;
        this.apiId = window.illness_search.api_id;
        this.paramsData = [];
        this.#prepareSettings();
        this.#extractDomains();
        this.#extractFeatures();
        this.#extractIllnesses();
        this.#extractParameters();
    }
    #prepareSettings(){
        const searchSettings = window.illness_search.search_settings;
        this.config = {
            settings: {
                advanced_search: searchSettings.advanced_search ?? false,
                content_type: searchSettings.content_type ?? 'params-list',
                joinParameters: searchSettings.joinParameters ?? false,
                selected_filters: searchSettings.selected_filters ?? 'all',
            },
            tabs: searchSettings.tabs.length > 0 ? searchSettings.tabs : []
        }

        console.log(this.config)
    }
    #extractFeatures(){
        this.features = {};
        if(this.data.cecha.length === 0) return;
        this.data.cecha.forEach(feature => {
            this.features[feature.id] = {
                id: feature.id,
                name: feature.nazwa,
                values: []
            };
        });
    }
    #extractIllnesses(){
        this.illnesses = {};
        if(this.data.choroba.length === 0) return;
        this.data.choroba.forEach(illness => {
            this.illnesses[illness.id] = {
                id: illness.id,
                name: illness.nazwa,
                parent: [],
                values: []
            };

            if(illness.dziedziny_chorobs.length > 0){
                illness.dziedziny_chorobs.forEach(domain => {
                    const domainId = domain.id;
                    if(domainId in this.domains){
                        this.illnesses[illness.id].parent.push(domainId);
                        this.domains[domainId].values.push(this.illnesses[illness.id]);
                    }
                })
            } else {
                if (this.domains['null']) {
                    this.domains['null'].values.push(this.illnesses[illness.id])
                }
            }
        });
    }
    #extractDomains(){
        this.domains = {};
        if(this.data.dziedzina.length === 0) {
            this.domains['null'] = {
                name: '',
                values: []
            }
            return;
        }
        this.data.dziedzina.forEach(domain => {
            this.domains[domain.id] = {
                id: domain.id,
                name: domain.nazwa,
                values: []
            };
        });
    }
    #extractParameters(){
        this.parameters = {};
        if(this.data.parameter.length === 0) return;
        this.data.parameter.forEach(param => {
            this.parameters[param.id] = {
                id: param.id,
                name: param.nazwa,
                groups: []
            };

            if(param.choroby_parametrows.length > 0){
                param.choroby_parametrows.forEach(illness => {
                    const illnessId = illness.id;
                    if(illnessId in this.illnesses){
                        this.illnesses[illnessId].values.push(this.parameters[param.id]);
                        this.parameters[param.id].groups.push(illnessId);
                    }
                });
            }

            if(param.cechy_grupujaces.length > 0){
                const featureId = param.cechy_grupujaces[0].id;
                if(featureId in this.features){
                    this.features[featureId].values.push(this.parameters[param.id]);
                    this.parameters[param.id].groups.push(featureId);
                }
            }
        });
    }
    SetQueryParams(params){
        this.queryParams = params;
        this.paramsData = [];
        this.#PrepareQueryParams();
    }
    #PrepareQueryParams(){
        const allowedCatIds = Object.keys(this.queryParams);
        Object.values(this.queryParams).forEach(values => {
            values.forEach(queryParamId => {
                this.data.parameter.forEach(parameter => {
                    if(parameter.id !== parseInt(queryParamId)) return;

                    let handler = null;
                    if(parameter.cechy_grupujaces.length > 0) handler = 'cechy_grupujaces';
                    else if(parameter.choroby_parametrows.length > 0) handler = 'choroby_parametrows';
                    else {
                        this.paramsData.push({
                            id: parameter.id,
                            name: parameter.nazwa,
                            active: false
                        });
                        return;
                    }

                    parameter[handler].forEach(handle => {
                        if(!allowedCatIds.includes(handle.id.toString())) return;
                        let index = this.paramsData.findIndex(el => el.id === handle.id && el.active === true);
                        if(index === -1) {
                            this.paramsData.push({
                                id: handle.id,
                                name: handle.nazwa,
                                active: true
                            });
                            index = this.paramsData.findIndex(el => el.id === handle.id && el.active === true);
                        }
                        this.paramsData = [
                            ...this.paramsData.slice(0, index),
                            {
                                id: parameter.id,
                                name: parameter.nazwa,
                                active: false,
                                parent: handle.id
                            },
                            ...this.paramsData.slice(index),
                        ];
                    })
                });
            });
        });
    }
    GetParamsData(){
        return this.paramsData.reverse().filter(elem => {
            if(this.config.settings.selected_filters === 'parent') return elem.active === true;
            if(this.config.settings.selected_filters === 'children') return elem.active !== true;
            return true;
        });
    }
    GetQueryParams(){
        return this.queryParams;
    }
    GetSettings(){
        return this.config ?? {};
    }
    GetFeatures(){
        return this.features;
    }
    GetIllnesses(){
        return this.illnesses;
    }
    GetDomains(){
        return this.domains;
    }
    GetParameters(){
        return this.parameters;
    }
    GetData(){
        return this.data;
    }
}

export default SearchForm;