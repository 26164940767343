<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div class="resultCard" :class="{ active: isOpen }">
    <div class="resultCard__wrapper">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.resultCard {
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 26px;

  @media (max-width: 992px){
    padding: 16px;
  }

  &.active {
    background-color: rgba(var(--primary-rgb), 0.1);
  }
}
</style>