<script>
import ArrowDownPrimaryIcon from '../assets/arrow-down-primary.svg?component';
import CloseIcon from '../assets/close.svg?component';
import CheckWhite from '../assets/check-white.svg?component';
import Checkbox from "./Checkbox.vue";
export default {
  props: {
    isBlock: {
      type: Boolean,
      default: false
    }
  },
  components: {Checkbox, ArrowDownPrimaryIcon, CheckWhite, CloseIcon},
  setup(){
    return {
      searchSettings: window.searchForm.GetSettings(),
      domains: window.searchForm.GetDomains(),
      features: window.searchForm.GetFeatures(),
      illnesses: window.searchForm.GetIllnesses(),
      parameters: window.searchForm.GetParameters(),
    };
  },
  data(){
    let queryData = window.searchForm.GetQueryParams();
    let activeElements = {};

    for(const [catId, values] of Object.entries(queryData)){
      activeElements[catId] = values.map(el => {
        return this.parameters[el] ? this.parameters[el] : false;
      })
    }
    return {
      activeTab: {},
      activeElements,
      activeGroups: [],
      activeDomains: []
    }
  },
  mounted() {
    if(Object.values(this.features).length > 0){
      Object.values(this.features).forEach(feature => {
        this.checkGroup(feature.id);
      });
    } else if(Object.values(this.illnesses).length > 0){
      Object.values(this.illnesses).forEach(illness => {
        this.checkGroup(illness.id);
      });
    }

    if(Object.values(this.domains).length > 0){
      Object.values(this.domains).forEach(domain => {
        this.checkDomains([domain.id]);
      })
    }
  },
  methods: {
    handleChangeTab(tab){
      this.activeTab = JSON.stringify(this.activeTab) === JSON.stringify(tab) ? {} : tab;
    },
    handleCloseFilters(){
      this.$emit('close-filters');
    },
    handleElementCheck(item, catId){
      if(!(catId in this.activeElements)) this.activeElements[catId] = [];
      if(this.activeElements[catId].includes(item)){
        let index = this.activeElements[catId].indexOf(item);
        this.activeElements[catId].splice(index, 1);
        this.checkGroup(catId);
        this.updateSearchParams();
        return;
      }
      this.activeElements[catId].push(item);
      this.checkGroup(catId);
      this.updateSearchParams();
    },
    handleGroupCheck(cat){
      if(this.activeGroups.includes(cat.id)){
        let index = this.activeGroups.indexOf(cat.id);
        this.triggerAllElements(cat.id, true);
        this.activeGroups.splice(index, 1);
        if(cat.parent && cat.parent.length > 0) this.checkDomains(cat.parent);
        return;
      }
      this.activeGroups.push(cat.id);
      this.triggerAllElements(cat.id);
      if(cat.parent && cat.parent.length > 0) this.checkDomains(cat.parent);
    },
    handleDomainCheck(cat){
      if(!this.activeDomains.includes(cat.id)){
        this.activeDomains.push(cat.id);
        if(cat.values.length === 0) return;

        cat.values.forEach(item => {
          if(this.activeGroups.includes(item.id)) return;
          this.handleGroupCheck(item);
        })

        return;
      }

      this.activeDomains.splice(this.activeDomains.indexOf(cat.id), 1);

      if(cat.values.length === 0) return;
      cat.values.forEach(item => this.handleGroupCheck(item))
    },
    triggerAllElements(catId, remove = false){
      let elements = {};
      if(catId in this.features) elements = this.features;
      if(catId in this.illnesses) elements = this.illnesses;

      if(catId in elements){
        if(!(catId in this.activeElements)) this.activeElements[catId] = [];
        elements[catId].values.forEach(value => {
          if(remove && this.activeElements[catId].includes(value)){
            let index = this.activeElements[catId].indexOf(value);
            this.activeElements[catId].splice(index, 1);
          } else if(!this.activeElements[catId].includes(value)) this.activeElements[catId].push(value);
        });
      }
      this.updateSearchParams();
    },
    checkGroup(catId){
      if(!catId || !(catId in this.activeElements)) return;
      let max = 0;

      if(catId in this.features) max = this.features[catId].values.length;
      else if(catId in this.illnesses) max = this.illnesses[catId].values.length;

      if(this.activeElements[catId].length !== max){
        let index = this.activeGroups.indexOf(catId);
        if(index !== -1) this.activeGroups.splice(index, 1);
      } else this.activeGroups.push(catId);
    },
    checkDomains(ids = []){
      if(ids.length === 0) return;
      ids.forEach(catId => {
        if(Object.values(this.domains).length === 0 || !(catId in this.domains)) return;
        let max = this.domains[catId].values.length;
        if(max === 0) return;

        let counter = 0;
        this.activeGroups.forEach(itemId => {
          if(this.domains[catId].values.find(element => element.id === itemId)) counter++
        });

        if(counter === max) this.activeDomains.push(catId);
        else {
          let index = this.activeDomains.indexOf(catId);
          if(index !== -1) this.activeDomains.splice(index, 1);
        }
      })
    },
    getTabContent(tabType){
      switch (tabType){
        case 'cecha':
        default:
          return this.features;
        case 'dziedzina':
          return this.domains;
        case 'choroba':
          return this.illnesses;
        case 'parameter':
          return {0:{
              id: "",
              name: "",
              values: Object.values(this.parameters)
            }};
      }
    },
    updateSearchParams(){
      const ids = {};
      for(const [key, value] of Object.entries(this.activeElements)){
        if(value.length === 0) continue;
        ids[key] = [];
        value.forEach(param => { ids[key].push(param.id); })
      }
      this.$emit('update-search-params', ids);
    },
    getCounts(type){
      switch (type){
        case 'group':
          return `(${this.activeGroups.length})`;
        case 'element':
        default:
          let count = 0;
          Object.values(this.activeElements).forEach(el => {count+=el.length});
          return `(${count})`;
      }
    },
    clearAllData(){
      this.activeGroups = [];
      this.activeDomains = [];
      this.activeElements = {};

      this.updateSearchParams();
    },
    handleSubmit(){
      this.$emit('submit');
    }
  },
  computed: {
    getFiltersWidth(){
      return this.isBlock ? '690px' : '1138px';
    },
    getFiltersPadding(){
      return this.isBlock ? '30px' : '30px 0';
    },
    getFiltersBorder(){
      return this.isBlock ? '20px' : '0';
    }
  }
}
</script>

<template>
<div class="searchForm">
  <div class="searchForm__navs">
    <div class="searchForm__nav" v-if="searchSettings.tabs.length > 1" v-for="(tab, index) in this.searchSettings.tabs" :class="{active: tab.name === activeTab.name}" @click="handleChangeTab(tab)" :data-counter-type="tab.counter">
      {{index + 1}}. {{ tab.name }} {{ getCounts(tab.counter) }}
    </div>
  </div>
  <div class="searchForm__filters" v-if="Object.keys(activeTab).length > 0">
    <div class="searchForm__wrapper">
      <div class="searchForm__tabs">
        <div class="clearAll" @click="clearAllData">Wyczyść wszystko</div>
        <div class="searchForm__tab" v-for="tab in this.searchSettings.tabs" :class="{active: tab.name === activeTab.name, noChildren: !tab.children}">
          <div class="searchForm__tab__item" v-for="cat in Object.values(getTabContent(tab.type)).sort((a,b) => a.name.localeCompare(b.name))" :class="{noChildren: !tab.children}">
            <p v-if="cat.name" :class="{handler: tab.type !== 'dziedzina'}" @click="tab.type !== 'dziedzina' ? handleGroupCheck(cat) : {}">
              <Checkbox :is-checked="this.activeGroups.includes(cat.id)" v-if="tab.type !== 'dziedzina'"/>
              {{cat.name}}
            </p>
            <ul v-if="tab.children">
              <li v-for="item in Object.values(cat.values).sort((a,b) => a.name.localeCompare(b.name))" @click="tab.counter === 'group' ? handleGroupCheck(item): handleElementCheck(item, cat.id)">
                <Checkbox :is-checked="tab.counter === 'group' ? this.activeGroups.includes(item.id) : (cat.id in this.activeElements && this.activeElements[cat.id].includes(item)) ? this.activeElements[cat.id].includes(item) : false"/>
                {{item.name}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <span class="search" @click="handleSubmit">Szukaj</span>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
.searchForm{
  &__wrapper{
    max-width: var(--width-main);
    width: 100%;
    margin: 0 auto;

    &.closeFilters{
      display: flex;
      justify-content: right;
      margin-bottom: 16px;
      max-width: v-bind(getFiltersWidth);

      span{
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        color: var(--primary);
      }
    }
  }

  &__navs{
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: 992px){
      flex-direction: column;
    }
  }

  .clearAll{
    width: max-content;
    margin-left: auto;
    text-decoration: underline;
    cursor: pointer;
  }

  span.search{
    display: block;
    background-color: var(--primary);
    border-radius: 62px;
    padding: 12px 64px;
    color: var(--white);
    margin: 20px 0 0 auto;
    width: max-content;
    cursor: pointer;
  }

  &__nav{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 26px;
    border-radius: 60px;
    background-color: var(--white);
    cursor: pointer;
    color: var(--gray-50);
    min-width: 250px;
    height: 50px;
    transition: background-color .3s ease, color .3s ease;

    @media (max-width: 992px){
      width: 100%;
    }

    &.active{
      color: var(--white);
      background-color: var(--primary);
    }
  }

  &__tabs{
    margin-top: 32px;

    @media (max-width: 992px){
      padding: 0 16px;
    }
  }

  &__tab{
    display: none;
    max-height: 350px;
    overflow-y: auto;
    padding-right: 20px;
    margin-top: 12px;

    &.active{
      display: block;
    }

    &.noChildren{
      grid-template-columns: repeat(4, 1fr);
      gap: 10px 24px;

      @media (max-width: 992px){
        grid-template-columns: 1fr;
        padding: 0;
      }

      &.active{
        display: grid;
      }
    }

    &__item{
      &:not(:first-child){
        margin-top: 32px;
      }

      > p {
        display: flex;
        gap: 10px;
        align-items: center;
        font-weight: 600;
        border-bottom: 1px solid #dadada;
        padding-bottom: 10px;
        margin-bottom: 10px;

        &.handler{
          cursor: pointer;
        }
      }

      &.noChildren{
        margin-top: 0;

        > p {
          display: flex;
          gap: 10px;
          align-items: center;
          font-weight: 400;
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px 24px;

      @media (max-width: 992px){
        grid-template-columns: 1fr;
      }

      li{
        display: flex;
        align-items: center;
        gap: 10px;
        color: var(--gray-50);
        cursor: pointer;
      }
    }
  }

  &__filters{
    width: 100%;
    background-color: var(--gray-10);
    padding: 20px 0;
  }
}
</style>