import axios from "axios";

class ApiToken {
    constructor() {
        let tokenData = JSON.parse(localStorage.getItem("ei_token_api"))
        if(tokenData){
            this.token = tokenData.token ?? '';
            this.expires_at = tokenData.expires_at ?? '';
        }
    }
    async check() {
        // if (!this.token || this.isExpired())
        await this.refresh();
    }
    isExpired(){
        return !this.expires_at || new Date(this.expires_at).getTime() - 4 * 60 * 60 * 1000 < (new Date()).getTime();
    }
    async refresh() {
        try {
            // https://ei-laravel.wldevs-stagging.pl/
            // http://127.0.0.1/
            const loginResponse = await axios.post(`${window.illness_search.api_url}/login`, {
                email: 'm.bandurski@blueowl.pl',
                password: 'blueowl123'
            });

            if (!loginResponse.data) {
                throw new Error(loginResponse.statusText);
            }

            const payload = loginResponse.data;

            this.token = payload.token;
            this.expires_at = payload.expires_at;
            localStorage.setItem('ei_token_api', JSON.stringify({
                'token': payload.token,
                'expires_at': payload.expires_at
            }));
            return true;
        } catch (error) {
            console.error('[API]', error);
            return false;
        }
    }
    getToken(){
        return this.token;
    };
    getExpiresAt(){
        return this.expires_at;
    }
}

export default ApiToken;