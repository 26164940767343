<script>
import axios from "axios";
import GoogleMap from "./GoogleMap.vue";
import SearchResultList from "../components/SearchResultList.vue";
import ApiToken from "../modules/ApiToken.js";
import SearchResults from "../modules/SearchResults.js";
import SearchForm from "../modules/SearchForm.js";
import ResultForm from "./ResultForm.vue";

const getBrowserInfo = () => JSON.stringify({
  userAgent: navigator.userAgent,
  language: navigator.language,
  platform: navigator.platform,
});

export default {
  components: {ResultForm, GoogleMap, SearchResultList },
  async setup() {
    const token = new ApiToken();
    await token.check();

    const responseSearch = await axios.get(
        `${window.illness_search.api_url}/get-parameters-by-search-id/${window.illness_search.api_id}`,
        {
          headers: {
            Authorization: `Bearer ${token.getToken()}`,
          },
        }
    );

    const urlParams = new URLSearchParams(window.location.search);
    const parameterString = urlParams.get("parameter");
    const parametersJson = parameterString ? JSON.parse(parameterString) : {};
    const parameters = [];
    if(Object.values(parametersJson).length > 0){
      Object.values(parametersJson).forEach(cat => {
        cat.forEach(param => {
          if(parameters.includes(param)) return;
          parameters.push({id: parseInt(param)});
        });
      })
    }

    const response = await axios.post(
        `${window.illness_search.api_url}/get-points-list`,
        {
          search_id: window.illness_search.api_id,
          localization: {
            lat: parseFloat(urlParams.get("lat")),
            lng: parseFloat(urlParams.get("lng")),
            browser_data: getBrowserInfo(),
            search_string: urlParams.get("loc"),
          },
          search_params: parameters,
          local: window.location.hostname === 'localhost'
        },
        {
          headers: {
            Authorization: `Bearer ${token.getToken()}`,
          },
        }
    );

    const data = response.data;
    window.searchResults = new SearchResults(data);
    window.searchResults.prepareData();

    const formSearchData = responseSearch.data;
    window.searchForm = new SearchForm(formSearchData.data);
    window.searchForm.SetQueryParams(parametersJson);
    return {
      data,
      formSearchData,
      location: {
        loc: urlParams.get("loc"),
        lng: parseFloat(urlParams.get("lng")),
        lat: parseFloat(urlParams.get("lat"))
      },
    };
  },
  methods: {
    async sendAnalytics(type, id) {
      const token = new ApiToken();
      await token.check();
      axios.post(
          `${window.illness_search.api_url}/add-result-data-download-info`,
          {
            uuid: this.data.add_stat_id,
            type: type ?? 'pdf',
            point: id ?? 0
          },
          {
            headers: {
              Authorization: `Bearer ${token.getToken()}`,
            },
          }
      );
    }
  }
};
</script>
<template>
  <div>
    <GoogleMap v-if="data.points" :items="data.points" />
    <ResultForm v-if="formSearchData.data" :location="location" :search-data="formSearchData.data"/>
    <SearchResultList v-if="data" :items="data" v-on:send-analytics="sendAnalytics"/>
  </div>
</template>
