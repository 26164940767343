<template>
  <div class="no-data">
    <h2>Brak wyników wyszukiwania.</h2>
  </div>
</template>

<style scoped lang="scss">
.no-data {
  h2 {
    text-align: center;
  }
}
</style>
