import { createApp } from 'vue';
import VueGoogleMaps from 'vue-google-maps-community-fork'; // https://vue-google-maps-community-fork.netlify.app/
import VueCookies from 'vue-cookies'
import App from './App.vue';
import Block from './Block.vue';
import MiniBlock from "./MiniBlock.vue";

if(document.querySelector('#search-app')){
    const app = createApp(App);
    app.use(VueGoogleMaps, {
        load: {
            key: 'AIzaSyAIJ8FRL1C6q6dBHsyPU3mZqEGLovP2Sk4' // prod: AIzaSyAIJ8FRL1C6q6dBHsyPU3mZqEGLovP2Sk4
        }
    })
    app.use(VueCookies, { expires: '20min'});
    app.mount('#search-app');
}

if(document.querySelector('#search-block')){
    const app = createApp(Block);
    app.use(VueCookies, { expires: '20min'});
    app.mount('#search-block');
}

if(document.querySelector('#mini-search-block')){
    const app = createApp(MiniBlock);
    app.use(VueCookies, { expires: '20min'});
    app.mount('#mini-search-block');
}