<script>
import CheckWhite from "../assets/check-white.svg";

export default {
  components: {CheckWhite},
  props: {
    isChecked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <span :class="{checked: isChecked}">
    <CheckWhite/>
  </span>
</template>
<style scoped lang="scss">
span{
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  width: 18px;
  min-height: 18px;
  height: 18px;
  border: 2px solid var(--primary);
  border-radius: 4px;
  margin-top: 4px;

  svg{
    display: none;
    width: 16px;
    height: 16px;
  }

  &.checked{
    background-color: var(--primary);

    svg{
      display: block;
    }
  }
}
</style>