<script>
import PointIcon from '../assets/point.svg?component';
export default {
  props: {
    number: {
      type: Number,
      required: true,
      default: 1
    }
  },
  components: {PointIcon}
}
</script>

<template>
  <span class="pointIcon">
      <PointIcon/>
      <span class="pointIcon__number">{{number}}</span>
  </span>
</template>

<style scoped lang="scss">
.pointIcon{
  display: block;
  width: max-content;
  position: relative;

  svg path{
    fill: var(--primary);
  }

  &__number{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 3px));
    font-size: 15px;
    font-weight: 700;
    color: var(--white);
  }
}
</style>