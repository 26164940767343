<script>
import { VueSpinnerGrid } from 'vue3-spinners';
export default {
  props: {
    size: {
      type: Number,
      default: 60
    }
  },
  components: {VueSpinnerGrid}
}
</script>

<template>
  <div class="loader">
    <VueSpinnerGrid :size="size" color="var(--primary)"/>
  </div>
</template>

<style scoped lang="scss">
.loader{
  width: max-content;
  margin: 24px auto;
}
</style>
