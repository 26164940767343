<script>
import axios from "axios";
import GoogleMap from "./GoogleMap.vue";
import SearchResultList from "../components/SearchResultList.vue";
import ApiToken from "../modules/ApiToken.js";
import SearchForm from "../modules/SearchForm.js";
import ResultForm from "./ResultForm.vue";

export default {
  components: {ResultForm, GoogleMap, SearchResultList },
  async setup() {
    const token = new ApiToken();
    await token.check();

    const responseSearch = await axios.get(
        `${window.illness_search.api_url}/get-parameters-by-search-id/${window.illness_search.api_id}`,
        {
          headers: {
            Authorization: `Bearer ${token.getToken()}`,
          },
        }
    );

    const formSearchData = responseSearch.data;
    window.searchForm = new SearchForm(formSearchData.data);
    window.searchForm.SetQueryParams({});
    return {
      formSearchData,
    };
  },
};
</script>

<template>
  <ResultForm v-if="formSearchData.data" :search-data="formSearchData.data" :is-block="true"/>
</template>

<style scoped lang="scss">

</style>