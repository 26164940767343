<script>
import SearchIconPrimary from '../assets/search-primary.svg?component';
import ArrowDown from '../assets/arrow-down.svg?component';
import ArrowDownPrimary from '../assets/arrow-down-primary.svg?component';
import MiniFilterForm from "../components/MiniFilterForm.vue";

export default {
    props: {
      location: {
        type: Object,
        default: {
          loc: '',
          lng: '',
          lat: ''
        }
      },
      searchData: {
        type: Object,
        default: {}
      }
    },
    components: {MiniFilterForm, SearchIconPrimary, ArrowDown, ArrowDownPrimary},
    setup(){
      const searchSettings = window.searchForm.GetSettings();
      return {
        paramsData: window.searchForm.GetParamsData(),
        queryParamsList: window.searchForm.GetQueryParams(),
        searchSettings
      };
    },
    data(){
      return {
        showFullList: false,
        showFilters: false,
        searchLocation: {
          loc: this.location.loc,
          lng: this.location.lng,
          lat: this.location.lat
        },
        suggestions: [],
        inputError: false,
        timeout: null,
        isTablet: window.innerWidth <= 1120,
        isMobile: window.innerWidth <= 992,
        timeoutController: null
      }
    },
  mounted() {
    document.addEventListener('click', () => {
      this.suggestions = [];
    });
    window.addEventListener('resize', () => {
      this.isTablet = window.innerWidth <= 1120;
      this.isMobile = window.innerWidth <= 992;
    });
  },
  methods: {
    handleFullList(){
      this.showFullList = !this.showFullList;
    },
    handleFilters(){
      this.showFilters = !this.showFilters;
    },
    handleUpdateSearchParams(values){
      this.queryParamsList = values;
      window.searchForm.SetQueryParams(values);
      this.paramsData = window.searchForm.GetParamsData()
      this.showFullList = false;
    },
    handleSuggestion(point){
      this.searchLocation = {
        loc: point.loc,
        lng: point.lng,
        lat: point.lat
      }
      this.suggestions = [];
      this.inputError = false;
    },
    preventClosing(e){
      e.preventDefault();
      e.stopPropagation();
    },
    async getHintsLocations(){
      const response = await fetch(`https://nominatim.openstreetmap.org/search?${new URLSearchParams({
        format: 'json',
        'accept-language': 'pl',
        countrycodes: 'pl',
        q: this.searchLocation.loc
      }).toString()}`);
      if (!response.ok) return;
      const json = await response.json();
      return Array.isArray(json) && json.length > 0 ? json : [];
    },
    async handleSubmit(e) {
      if (e) e.preventDefault();

      this.inputError = false;
      if(this.searchLocation.loc.length === 0){
        this.inputError = true;
        return;
      }

      try {
        const json = await this.getHintsLocations();
        if(json.length === 0) return;

        this.searchLocation.lng = json[0].lon;
        this.searchLocation.lat = json[0].lat;
        this.searchLocation.loc = json[0].display_name;
      } catch (err){
        console.error(err);
      } finally {
        const queryString = new URLSearchParams({
          parameter: JSON.stringify(this.queryParamsList),
          lng: this.searchLocation.lng,
          lat: this.searchLocation.lat,
          loc: this.searchLocation.loc
        });
        window.location = `${window.illness_search.search_page_url}/?${queryString.toString()}`;
      }
    },
    handleInput(e) {
      this.searchLocation.loc = e.target.value;
      this.searchLocation.lng = '';
      this.searchLocation.lat = '';
      this.inputError = false;
      if(this.searchLocation.loc.length === 0){
        this.inputError = true;
        this.suggestions = [];
        clearTimeout(this.timeout);
        return;
      }

      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        try {
          const json = await this.getHintsLocations();
          if(this.timeout !== this.timeoutController) return;
          this.suggestions = [];
          if(json.length === 0) return;
          json.forEach(point => {
            this.suggestions.push({
              loc: point.display_name,
              lat: point.lat,
              lng: point.lon
            });
          });
          this.suggestions.sort((a,b) => a.loc.localeCompare(b.loc));
        } catch (err){
          console.error(err);
        }
      }, 200);
      this.timeoutController = this.timeout;
    }
  }
};
</script>

<template>
  <div class="resultForm">
    <form @submit="handleSubmit">
      <div class="resultForm__input">
        <input type="text" :value="searchLocation.loc" @input="handleInput" placeholder="Wpisz miasto, ulicę" :class="{isError: inputError}">
        <SearchIconPrimary @click="handleSubmit"/>
        <ul class="resultForm__suggestions" v-if="suggestions.length > 0" @click="preventClosing">
          <li v-for="point in suggestions" @click="handleSuggestion(point)">
            {{point.loc}}
          </li>
        </ul>
      </div>
    </form>
    <div v-if="searchSettings.settings.advanced_search && isTablet">
      <MiniFilterForm v-on:close-filters="handleFilters" v-on:update-search-params="handleUpdateSearchParams" v-on:submit="handleSubmit"/>
    </div>
    <button class="btn btn--primaryFill" v-if="isMobile" @click="handleSubmit">Szukaj</button>
  </div>
</template>

<style scoped lang="scss">
.resultForm{
  display: flex;
  gap: 16px;

  @media (max-width: 1120px){
    flex-direction: column;
    gap: 10px;
  }

  form{
    display: flex;
    align-items: center;
    gap: 16px;
    max-width: var(--width-main);
    width: 100%;
    margin: 0 auto;

    h1{
      min-width: max-content;
      margin: 0;
    }
  }

  button.btn{
    width: 100%;
  }

  &__suggestions {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    padding: 20px;
    border-radius: 20px;
    background-color: var(--white);
    box-shadow: 1px 1px 3px 0 var(--gray-50);
    max-width: 100%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 99;

    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    margin: 0;

    li{
      cursor: pointer;
      font-size: 14px;
      transition: color .3s ease;

      &:hover{
        color: var(--primary);
      }
    }
  }

  &__input{
    position: relative;
    width: 100%;

    input{
      width: 100%;
      padding: 16px 48px 16px 26px;
      border-radius: 50px;
      border: none;
      font-weight: 500;
      background-color: var(--white);
      outline: none;
      height: 50px;
      color: var(--gray-50);

      &.isError{
        border: 2px solid #f00;
        color: #f00;
      }
    }

    svg{
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}
</style>