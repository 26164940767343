<script>
import CheckIcon from '../../assets/check.svg'

export default {
  props: {
    parameters: {
      type: Object,
      required: true,
      default: {}
    },
    productId: {
      type: [Number, Boolean],
      required: true,
    },
    joinElementsToString: {
      type: Boolean,
      default: false
    }
  },
  components: {CheckIcon},
  setup(){
    return {
      apiId: window.illness_search.api_id
    }
  },
  computed: {
    getParameters(){
      const params = this.prepareParameters(
        Object.values(this.parameters).filter(param => { // validate
          return !param.inMix;
        }).sort((a,b) => a.name.localeCompare(b.name)),
        true
      );

      return this.joinElementsToString ? [{html: params.map(param => param.html).join(', ')}] : params;
    },
    getMixes(){
      if(this.productId === false) return [];
      const mixes = window.searchResults.GetMixByProductId(this.productId);
      return mixes.sort((a,b) => a.name.localeCompare(b.name));
    },
    columnsCount(){
      return this.joinElementsToString ? 'repeat(1, 1fr)' : 'repeat(4, 1fr)';
    },
  },
  methods: {
    prepareParameters(params, isBold = false){
      if(params.length === 0) return [];
      const parameters = [];
      let styles = 'color: var(--primary);';
      styles += isBold ? 'font-weight:700;' : '';
      styles = `style='${styles}'`;

      params.forEach(param => {
        parameters.push({
          ...param,
          html: `<span ${param.active ? styles : ''}>${param.name}</span>`
        })
      })
      return parameters;
    },
  }
}
</script>

<template>
  <div class="productParameters">
    <ul>
      <li v-for="param in getParameters" :class="{space: getParameters.length > 1, last: param.name === 'CCD'}">
        <CheckIcon class="check" />
        <span v-html="param.html"></span>
      </li>
    </ul>
    <ul v-if="getMixes.length > 0" class="mixes">
      <li v-for="mix in getMixes" :class="{space: getMixes.length > 1}">
        <CheckIcon class="check" />
        <span>
          <strong>{{mix.name}}: </strong>
          <span v-html="prepareParameters(mix.parameters).map(param => param.html).join(', ')"></span>
        </span>
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
.productParameters{
  margin: 20px 0 0 0;
  padding: 30px 42px;
  background-color: var(--white);
  border-radius: 20px;

  @media (max-width: 992px){
    padding: 16px 24px;
  }

  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: v-bind(columnsCount);
    gap: 12px;

    @media (max-width: 992px){
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 576px){
      grid-template-columns: 1fr;
    }

    li{
      display: flex;
      flex-wrap: nowrap;
      color: var(--gray-50);

      &.space{
        margin-bottom: 16px;
      }

      &.last{
        order: 1;
      }
    }

    &.mixes{
      margin-top: 24px;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  svg.check{
    display: block;
    margin-right: 10px;
    margin-top: 4px;
    min-width: 19px;
  }
}
</style>