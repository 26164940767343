<script>
import CheckIcon from "../../assets/check.svg";
import mime from "mime-types";
import PDFIcon from "../../assets/pdf.svg";

export default {
  components: {PDFIcon, CheckIcon},
  props: {
    title: {
      type: String,
      default: 'Dostępne testy'
    },
    products: {
      type: Object,
      required: true
    }
  },
  methods: {
    clickDownload(url, name, mimeType) {
      let link = document.createElement('a');
      link.download = `${name.split(' ').join('-')}.${mime.extension(mimeType)}`;
      link.target = '_blank';
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<template>
  <div class="resultDataTable">
    <p><strong>{{title !== 'Pojedynczy parametr' ? title : 'Dostępne testy'}}:</strong></p>
    <div class="resultDataTable__wrapper"><!--Rynkowa -->
      <table>
        <tr v-for="product in products">
          <td>
            <CheckIcon class="check" />
            {{product.paramsLabel}}
          </td>
          <td>{{product.classes}}</td>
          <td>{{product.methods}}</td>
          <td v-if="product.file">
            <span class="download" @click="clickDownload(product.file.url, product.file.fileName, product.file.mimeType)">
              <span>{{product.file.fileName}}</span><PDFIcon/>
            </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped lang="scss">
.resultDataTable{
  margin-top: 24px;

  &__wrapper{
    background-color: var(--white);
    padding: 18px 32px;
    border-radius: 20px;

    @media (max-width: 992px){
      padding: 12px 16px;
      overflow-x: auto;
      max-width: 100%;
    }
  }

  table{
    border-spacing: 0 8px;

    @media (max-width: 992px){
      width: max-content;
    }

    td{
      min-width: 100px;

      &:first-child{
        display: flex;
        align-items: center;
        gap: 14px;
      }

      .check{
        min-width: 16px;
      }

      &:not(:last-child){
        padding-right: 45px;
      }
    }

    .download{
      display: flex;
      gap: 6px;
      align-items: center;
      justify-content: right;
      text-align: right;
      text-decoration: underline;
      cursor: pointer;
      transition: color .3s ease;

      &:hover{
        color: var(--primary);
      }

      svg{
        width: 28px;
        height: auto;
      }
    }
  }
}
</style>