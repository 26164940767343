class SearchResults{
    constructor(data) {
        this.data = data;
        console.log('data', this.data)
    }
    prepareData(){
        this.groups = {};
        this.products = {};
        this.parameters = {};
        this.mixes = {};

        this.#extractGroups();
        this.#extractProducts();
        this.#extractProductParams();
        this.#extractProductMixes();
    }
    #extractGroups(){
        const groups = {};

        Object.values(this.data.grupy_badan).forEach(group => {
            if(!group) return;
            groups[group.id] = {
                id: group.id,
                name: group.nazwa,
                level: group.ranga ?? 999
            };
        });

        this.groups = groups;
    }
    #extractProducts(){
        const products = {};

        Object.values(this.data.products).forEach(product => {
           products[product.id] = {
               id: product.id,
               name: product.nazwa_rynkowa,
               tradeName: product.nazwa_handlowa,
               paramsLabel: product.params_string,
               classes: product.classes,
               methods: product.methods,
               file: product.downloadable_file === '1' ? {
                   fileName: product.downloadable_file_name,
                   mimeType: product.downloadable_file_mime,
                   url: product.downloadable_file_url
               } : null,
               group_id: product.grupa_badan_id
           };
        });

        this.products = products;
    }
    #extractProductParams(){
        const params = {};

        Object.values(this.data.products).forEach(product => { // validate
            if(!(product.id in params)) params[product.id] = {};
            if(product.parameters.length > 0){
                product.parameters.forEach(param => {
                    if(param.id in params) return;
                    params[product.id][param.id] = {
                        id: param.id,
                        name: param.nazwa,
                        inMix: false,
                        active: param.is_active
                    }
                });
            }

            if(product.mixes.length > 0){
                product.mixes.forEach(mixes => {
                    if(mixes.mix_parameters.length === 0) return;
                    mixes.mix_parameters.forEach(param => {
                        if(param.id in params) return;
                        params[product.id][param.id] = {
                            id: param.id,
                            name: param.nazwa,
                            inMix: true,
                            active: param.is_active
                        }
                    });
                });
            }
        });

        this.parameters = params;
    }
    #extractProductMixes(){
        const mixes = [];

        Object.values(this.data.products).forEach(product => {
            if(product.mixes.length === 0) return;
            product.mixes.forEach(mix => {
                mixes.push({
                    id: mix.mix_id,
                    name: mix.mix_name,
                    product_id: product.id,
                    parameters: mix.mix_parameters.map(param => {
                        return this.GetParamById(param.id, product.id)
                    })
                });
            });
        });

        this.mixes = mixes;
    }
    GetParamById(param_id, product_id){
        if(product_id in this.parameters){
            return (param_id in this.parameters[product_id]) ? this.parameters[product_id][param_id] : {};
        }
        return {};
    }
    GetMixByProductId(product_id){
        if(this.mixes.length === 0) return [];
        return this.mixes.filter(mix => mix.product_id === product_id);
    }
    GetPointGroupsForProducts(products){
        if(Object.keys(this.products).length === 0) return {};
        if(products.length === 0) return {};
        const groups = {};
        products.forEach(product => {
            if(product.product_id in this.products){
                let group = {};
                if(this.products[product.product_id].group_id){
                    if(!(this.products[product.product_id].group_id in groups)){
                        group = this.groups[this.products[product.product_id].group_id];
                        if(!group) return;
                        group.products = {};
                        groups[this.products[product.product_id].group_id] = group;
                    } else group = groups[this.products[product.product_id].group_id];
                } else{
                    if(!groups["null"]){
                        groups["null"] = {
                            products: {}
                        };
                    }
                    group = groups["null"];
                }

                let _product = {};
                if(!(this.products[product.product_id].id in group.products)){
                    _product = {
                        ...this.products[product.product_id],
                        parameters: {}
                    }
                    group.products[this.products[product.product_id].id] = _product;
                } else _product = group.products[this.products[product.product_id].id];

                product.params_array.forEach(param_id => {
                    if(param_id in _product.parameters) return;
                    _product.parameters[param_id] = this.GetParamById(param_id, product.product_id);
                });

                groups[this.products[product.product_id].group_id ?? "null"] = group;
            }
        });

        return groups;
    }
    GetData(){
        return {
            groups: this.groups,
            products: this.products
        };
    }
}

export default SearchResults;